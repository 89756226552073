body { background-color: $COL100; }
[data-pad="0"] { margin-top: 20px; }

@media #{$large-up} {
[data-pad="0"] { margin-top: 25px; }

}
[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL108;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL110;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL122;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
h4 {
color: $COL114;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 60px;

}
}
h5 {
color: $COL115;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h6 {
color: $COL124;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-decoration: underline;
font-size: 16px;

@media #{$medium-up} {
font-size: 6px;

}
@media #{$large-up} {
font-size: 16px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 13px;

}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 13px;

}
ol,ul {
color: $COL128;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 13px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL126;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 60px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 13px;

}
}
p { padding-bottom: 1em; }
a {color: $COL143}
a:hover {color: $COL141}
/* Primary:62 */
.MES62 {
background-color: $COL138;
color: $COL105;
 }
/* Primary:63 */
.MES63 {
background-color: $COL138;
color: $COL105;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
 }
.MES63 {
background-color: $COL138;
color: $COL105;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
h1.MEC63, h2.MEC63, h3.MEC63, h4.MEC63, h5.MEC63, h6.MEC63 { color: $COL105;
 }
 }
cite.MEC63{
color: $COL105;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* Secondary:64 */
.MES64 {
background-color: $COL132;
color: $COL107;
padding: 10px 15px;

 }
/* Secondary:65 */
.MES65 {
background-color: $COL132;
color: $COL107;
 }
.MES65 {
background-color: $COL132;
color: $COL107;
h1.MEC65, h2.MEC65, h3.MEC65, h4.MEC65, h5.MEC65, h6.MEC65 { color: $COL107;
 }
 }
cite.MEC65{
color: $COL107;
}
/* Shade 1:66 */
.MES66 {
background-color: $COL112;
color: $COL113;
 }
/* Shade 1:67 */
.MES67 {
background-color: $COL112;
color: $COL113;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: $COL137;
 }
.MES67 {
background-color: $COL112;
color: $COL113;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: $COL137;
h1.MEC67, h2.MEC67, h3.MEC67, h4.MEC67, h5.MEC67, h6.MEC67 { color: $COL113;
 }
 }
cite.MEC67{
color: $COL113;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* Header:68 */
.MES68 {
background-color: $COL129;
color: $COL144;
 }
.MES68 {
background-color: $COL129;
color: $COL144;
 }
a.MEC68 { color: $COL135;
&:hover { color: $COL135; }
 }
cite.MEC68{
color: $COL144;
}
/* Main Menu:69 */
nav.me-Menu.MES69 {
.menu-item.MEC69{background-color: $COL138;
 &:hover {background-color: $COL132}
}
& .menu-item.MEC69, & .menu-item.MEC69 > div.MEC69{ & > a.MEC69{color: $COL132;
text-transform: uppercase;
}
 &:hover > a.MEC69{color: $COL129;
}
 }
&.horizontal > .menu-item.MEC69 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC69 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC69 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL129;
border-style: solid;
 }
&.horizontal > .menu-item.MEC69 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 25px 20px;}

& .sub-menu .menu-item a{padding: 15px 10px;}


& > .menu-item.MEC69.active { background-color: $COL132;
& > a{ color: $COL129;}
 }
&.horizontal .menu-item.MEC69:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL132;}}
&.vertical .menu-item.MEC69:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL132;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC69{background-color: $COL132;
 &:hover {background-color: $COL133}
}
& .menu-item.MEC69, & .menu-item.MEC69 > div.MEC69{ & > a.MEC69{color: $COL129;
font-size: 14px;
@media #{$large-up} {
font-size: 16.1px;
}text-transform: capitalize;
}
 &:hover > a.MEC69{color: $COL129;
}
 }

}
}
 }
/* Banner Slider Left Panel:70 */
.MES70 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://drainstormers.com.au/img/2180/473');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:154px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES70 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://drainstormers.com.au/img/2180/473');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:154px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 400px;};
 }
/* Slider Right Panel:71 */
.MES71 {
background-color: $COL103;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://drainstormers.com.au/img/2204/480');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL134;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 15px;

 }
.MES71 {
background-color: $COL103;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://drainstormers.com.au/img/2204/480');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL134;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 15px;

 }
cite.MEC71{
color: $COL134;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* Banner Slider:72 */
.MES72 {
& .slider-arrow {background-color: $COL135;
color: $COL129;
font-size: 20px;
@media #{$medium-up} {
font-size: 20px;
};
@media #{$large-up} {
font-size: 20px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL136;
border-radius: 50%;
border-style:solid;
border-width:1px;
@media #{$medium-up} {
border-width: 1px;
};
@media #{$large-up} {
border-width: 1px;};
&:hover{border-color: $COL135;
;}
background-color: $COL136;
background-clip: padding-box;
&:hover {background-color: $COL135;}
width:10px;
height:10px;
@media #{$medium-up} {
width:10px;
height:10px;
};
@media #{$large-up} {
width:10px;
height:10px;
};
&:hover{background-color: $COL135;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL135;
background-color: $COL135;
  }
}
 }
/* Footer Panel:73 */
.MES73 {
background-color: $COL103;
color: $COL132;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 11.05px;
};
padding: 20px 15px;

border-width: 10px 0 0 0;
border-style: solid;
border-color: $COL133 transparent transparent transparent;
 }
.MES73 {
background-color: $COL103;
color: $COL132;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 11.05px;
};
padding: 20px 15px;

border-width: 10px 0 0 0;
border-style: solid;
border-color: $COL133 transparent transparent transparent;
h1.MEC73, h2.MEC73, h3.MEC73, h4.MEC73, h5.MEC73, h6.MEC73 { color: $COL132;
 }
 }
cite.MEC73{
color: $COL132;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 11.05px;
};
}
/* Footer Menu:74 */
nav.me-Menu.MES74 {
& .menu-item.MEC74, & .menu-item.MEC74 > div.MEC74{ & > a.MEC74{color: $COL132;
font-size: 11.9px;
text-transform: capitalize;
}
 &:hover > a.MEC74{color: $COL132;
}
 }
&.horizontal > .menu-item.MEC74 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC74 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC74 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC74 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Content Panel:75 */
.MES75 {
background-color: $COL129;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
.MES75 {
background-color: $COL129;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px;

}
h1.MEC75 { @media #{$large-up} { font-size: 36px; }; }
h2.MEC75 { @media #{$large-up} { font-size: 31.5px; }; }
h3.MEC75 { @media #{$large-up} { font-size: 18px; }; }
h4.MEC75 { @media #{$large-up} { font-size: 54px; }; }
h5.MEC75 { @media #{$large-up} { font-size: 36px; }; }
h6.MEC75 { @media #{$large-up} { font-size: 14.4px; }; }
 }
cite.MEC75{
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* right menu head:76 */
.MES76 {
background-color: $COL132;
color: $COL129;
padding: 25px 30px;

 }
.MES76 {
background-color: $COL132;
color: $COL129;
padding: 25px 30px;

h1.MEC76, h2.MEC76, h3.MEC76, h4.MEC76, h5.MEC76, h6.MEC76 { color: $COL129;
 }
h1.MEC76 { font-size: 23.75px; }
h2.MEC76 { font-size: 20.9px; }
h3.MEC76 { font-size: 19px; }
h4.MEC76 { font-size: 17.1px; }
h5.MEC76 { font-size: 16.15px; }
h6.MEC76 { font-size: 15.2px; }
 }
cite.MEC76{
color: $COL129;
}
/* right menu panel:77 */
.MES77 {
background-color: $COL138;
color: $COL134;
padding: 10px;

 }
.MES77 {
background-color: $COL138;
color: $COL134;
padding: 10px;

h1.MEC77, h2.MEC77, h3.MEC77, h4.MEC77, h5.MEC77, h6.MEC77 { color: $COL134;
 }
 }
a.MEC77 { color: $COL134;
&:hover { color: $COL134; }
 }
cite.MEC77{
color: $COL134;
}
/* right menu divider:78 */
.MES78 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL112 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Light:79 */
.MES79 {
background-color: $COL139;
 }
/* Light:80 */
.MES80 {
background-color: $COL139;
color: $COL135;
padding: 10px 30px;

 }
.MES80 {
background-color: $COL139;
color: $COL135;
padding: 10px 30px;

 }
a.MEC80 { color: $COL138;
&:hover { color: $COL138; }
 }
cite.MEC80{
color: $COL135;
}
/* Emergency:81 */
.MES81 {
background-color: $COL138;
color: $COL135;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES81 {
background-color: $COL138;
color: $COL135;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
 }
cite.MEC81{
color: $COL135;
}
/* Credit:85 */
.MES85 {
background-color: $COL100;
color: $COL147;
padding: 5px 15px 10px 15px;

 }
.MES85 {
background-color: $COL100;
color: $COL147;
padding: 5px 15px 10px 15px;

 }
cite.MEC85{
color: $COL147;
}
